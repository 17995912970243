<template>
<div>
    <div class="vx-row mb-12">
        <vs-button v-on:click="create()" icon="done" color="primary">Create Payment</vs-button>
    </div>
    <div class="vx-row mb-12">
        <div v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]">
            <data-table :responseData="responseData" :propsParams="params" :header="header" @reloadDataFromChild="reloadData">
                <template slot="thead">
                    <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
                </template>
                <template slot="tbody">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
                        <vs-td class="whitespace-no-wrap">
                            <div class="mt-4 flex mr-1">
                                <vx-tooltip text="Confirm AR-Clearing" class="mr-4">
                                    <vs-button color="primary" type="line" icon-pack="feather" @click="promptConfirm(tr)" icon="icon-check-square" />
                                </vx-tooltip>
                                <vx-tooltip text="Show AR-Clearing" class="mr-4">
                                    <vs-button color="green" type="line" icon-pack="feather" @click="edit(tr)" icon="icon-eye" />
                                </vx-tooltip>

                                <vx-tooltip text="Cancel AR-Clearing" class="mr-4">
                                    <vs-button color="red" type="line" icon-pack="feather" @click="promptCancel(tr)" icon="icon-x-square" />
                                </vx-tooltip>
                            </div>
                        </vs-td>
                        <vs-td>
							Inv Code : {{ tr.Lines[0].ReferenceCode }} <br>
							Partner : ({{ tr.Lines[0].PersonCode }}) {{ tr.Lines[0].PersonName }} <br>
							Adjustment : {{ priceFormat(tr.Lines[0].AdjustmentValue) }}
                        </vs-td>
                        <vs-td>
                            Operating Unit Code : {{tr.OperatingUnitCode}}<br>
                            Territory Code : {{tr.TerritoryCode}} <br>
                            Territory Name : {{tr.TerritoryName}} <br>
							Code : {{tr.Code}}
                        </vs-td>
                        <vs-td>
                            {{ dateFormat(tr.PostingDate) }}
                        </vs-td>
                        <vs-td>
                            {{ dateFormat(tr.CreatedAt) }}
                        </vs-td>
                    </vs-tr>
                </template>
            </data-table>
        </div>
        <vs-prompt title="Confirmation" color="primary" @accept="confirmAdjustment" @cancel="closeDetail" @close="closeDetail" :buttons-hidden="false" :active.sync="confirmPrompt">
            <div class="con-exemple-prompt">
                AR Clearing
                <br />
                Are you sure to confirm <b>{{this.selectedData.Code}}</b> AR-Clearing ?
                <br />
                <br />
                <!-- <vs-textarea v-model="notes"/> -->
            </div>
        </vs-prompt>
        <vs-prompt title="Confirmation" color="primary" @accept="cancelAdjustment" @cancel="closeDetail" @close="closeDetail" :buttons-hidden="false" :active.sync="cancelPrompt">
            <div class="con-exemple-prompt">
                AR Clearing
                <br />
                Are you sure to cancel <b>{{this.selectedData.Code}}</b> AR-Clearing ?
                <br />
                <br />
                <!-- <vs-textarea v-model="notes"/> -->
            </div>
        </vs-prompt>
        <transition name="detail-fade">
            <div v-if="detail" v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]">
                <div>
                    <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth" vs-w="4">
                        <vs-button size="small" v-on:click="closeDetail" color="grey" icon-pack="feather" icon="icon-x-square">Close</vs-button>
                    </vs-col>
                    <template v-if="selectedData.ID == 0">
                        <create @closeDetail="closeDetail" />
                    </template>
                    <template v-else>
                        <edit :selected="selectedData" @closeDetail="closeDetail" />
                    </template>
                </div>
            </div>
        </transition>
    </div>
</div>
</template>

<script>
import create from "../form-create.vue";
import edit from "../form-edit.vue";
import moment from "moment";
export default {
    props: {
        selected: Object,
        option: Object,
    },
    components: {
        create,
        edit
    },
    data() {
        return {
            typePayment: ["", "Giro", "Cheque", "Transfer"],
            confirmPrompt: false,
            cancelPrompt: false,
            status: 0,
            params: {
                search: "",
                length: 10,
                page: 1,
                order: "desc",
                sort: "created_at",
            },
            header: [{
                    text: "Action",
                    sortable: false,
                },
                {
                    text: "Invoice",
                    sortable: false,
                    // width: '5%'
                },
                {
                    text: "Adjustment",
                    value: "code",
                },
                {
                    text: "Posting Date",
                    value: "date_posting",
                },
                {
                    text: "Created Date",
                    value: "created_at",
                },
            ],
            responseData: {},
            detailShow: "vx-col md:w-1/2 w-full mb-base",
            detailHide: "core vx-col md:w-1/1 w-full mb-base",
            detail: false,
            selectedData: {},
        };
    },
    computed: {},
    watch: {},
    mounted() {
        // this.reloadData(this.params);

        // this.getData();
        // this.page(1);
        // this.dataId = this.data[0].id
    },
    methods: {
        create() {
            this.selectedData = {
                ID: 0
            }
            this.detail = true;
        },
        edit(selected) {
            this.selectedData = selected
            console.log(selected)
            this.detail = true;
        },
        promptConfirm(data) {
            this.confirmPrompt = true
            this.status = 2
            this.cancelPrompt = false
            this.selectedData = data
            console.log("confirm")
        },
        promptCancel(data) {
            this.confirmPrompt = false
            this.status = 4
            this.cancelPrompt = true
            this.selectedData = data
            console.log("confirm")
        },
        dateFormat(value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        closeDetail(params = false) {
            console.log(params)
            window.scrollTo(0, 0);
            this.detail = false;
            this.confirmPrompt = false
            this.status = 2
            this.cancelPrompt = false
            if (params == true || this.selectedData.ID != 0) {
                this.reloadData(this.params);
            }
            this.selectedData = {
                ID: 0,
            };
        },
        confirmAdjustment() {
            let params = {
                id: this.selectedData.ID,
                status: this.status
            }
            this.$vs.loading();
            this.$http.post('/api/v1/finance/adjustment/confirm', params).then((result) => {
                this.$vs.loading.close();
                if (result.code == 200) {
                    this.closeDetail(true)
                    this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check",
                    });
                } else {
                    this.closeDetail()
                    this.$vs.notify({
                        title: "Error",
                        text: result.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check",
                    });
                }
            }).catch((e) => {
                console.log(e)

            })
        },
        cancelAdjustment() {
            let params = {
                id: this.selectedData.ID,
                status: this.status
            }
            this.$vs.loading();
            this.$http.post('/api/v1/finance/adjustment/cancel', params).then((result) => {
                this.$vs.loading.close();
                if (result.code == 200) {
                    this.closeDetail()
                    this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check",
                    });
                } else {
                    this.closeDetail()
                    this.$vs.notify({
                        title: "Error",
                        text: result.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check",
                    });
                }
            }).catch((e) => {
                console.log(e)

            })
        },
        dataTable(params) {
            return new Promise((resolve, reject) => {
                this.$http.get('/api/v1/finance/adjustment/data-table', {
                    params: {
                        status: 0,
                        type: 1,
                        search: params.search,
                        length: params.length,
                        page: params.page,
                        order: params.order,
                        sort: params.sort,
                    }
                }).then((r) => {
                    resolve(r)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        reloadData(params) {
            this.params = params;
            console.log(params);
            this.$vs.loading();
            const dataTable = this.dataTable(params);
            dataTable.then((r) => {
                console.log(r);
                if (r.code == 500) {
                    this.$vs.loading.close();
                } else if (r.code == 200) {
                    this.$vs.loading.close();
                    this.data = r.data.records;
                    this.responseData = r.data;
                    // this.responseData.length = r.data.otherPayment.length;
                    // this.checkedAll = false;
                } else {
                    this.$vs.loading.close();
                }
            });

        },
    },
};
</script>
 
<style scoped>
.nonfixed {
    position: inherit;
    padding-left: 20px;
}

.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transform-origin-y: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-right: 1px solid #7367f0;
    /* min-height: 800px; */
    /* height: 100%; */
    -webkit-mask-position-y: fixed;
    /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
    background: beige;
}

.colored {
    border: 1px solid #7367f0;
    position: fixed;
    left: 4%;
    top: 40%;
    max-width: 45%;
    z-index: 999999999999;
    background: antiquewhite;
    background-color: antiquewhite;
}
</style>
